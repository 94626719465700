.nav-button {
    display: inline-block;
    padding: $lh / 4;
    color: #DA5B24;
    text-decoration: none;
    // background: #D01E79;
    // filter: drop-shadow(0px 0px 4px rgba(0,0,0,0.8));
    // box-shadow: inset 0px 0px 10px rgba(0,0,0,0.5);
    border-radius: $radius / 4;
    margin-top: $lh / 4;
    margin-left: $lh / 4;
    color: white;
    font-size: 0.85em;
    font-size: $big;
}

.nav-button-small {
}

.nav-button-closed {
    display: inline-block;
    padding: $lh / 4;
    text-decoration: none;
    background: grey;
    filter: drop-shadow(0px 0px 4px rgba(0,0,0,0.8));
    box-shadow: inset 0px 0px 10px rgba(0,0,0,0.5);
    border-radius: $radius / 4;
    margin-top: $lh / 4;
    margin-left: $lh / 4;
    color: white;
    opacity: 0.3;
    border-radius: $radius * 2;
}

.chat-content-message {
    margin-top: 0.1em;
    font-size: 0.9em;
}