.auth {
    position: absolute;
    top: 25%; 
    left: 50%;
    transform: translate(-50%,-50%);
    background: #571f91;
    border-radius: $radius;
    z-index: 900;
    width: 20em;
    padding: $lh;
    backdrop-filter: blur(6px);
    filter: drop-shadow(0px 0px 4px rgba(0,0,0,0.8));
    box-shadow: inset 0px 0px 10px rgba(0,0,0,0.5);   
}

.auth-title {
    text-align: center;
    padding-bottom: $lh / 2;
}