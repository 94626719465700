$pc: white;
$sc: #b513ff;



$radius: 0.5em;
$lh: 1.4em;
$big: 1.55rem;
$medium: 1.25rem;


$g1: linear-gradient(90deg, #83911F 0%, #DA5B24 35%, #6F997E 100%);