.instructions-container {
    position: fixed;
    top: 75%;
    left: 50%;
    z-index: 1000;
    padding-bottom: $lh / 4;
    font-size: 1.2em; 
    color: white;
    padding: $lh / 2;
    transform: translate(-50%, -50%);
    border-radius: $radius / 2;
    background: rgba(0,0,0,0.9);
    background-color: #DA5B24;
    // color: black;
    // backdrop-filter: brightness(100%) blur(10px);
    pointer-events: none;
    text-align: center;
    max-width: 19em;
    width: 100%;
    filter: drop-shadow(0px 0px 4px rgba(0,0,0,0.8));
    box-shadow: inset 0px 0px 10px rgba(0,0,0,0.5);

    span {
        background: white;
        color: black;
        font-size: 0.9em;
        border-radius: 0.1em;
        line-height: 0.9;
        display: inline-block;
        padding: 0.2em;
        padding-bottom: 0.2em;
        padding-top: 0;
        margin-left: 0.15em;
        width: 1.2em;
        text-align: center;
    }
}